export enum VerificationAppDocumentType {
  "UserInformation" = "UserInformation",
  "Residence" = "Residence",
  "DriverLicence" = "DriverLicence",
  "Passport" = "Passport",
  "Id" = "Id",
  "Selfie" = "Selfie",
  "BeneficialOwnership" = "BeneficialOwnership",
  "ProofOfResidence" = "ProofOfResidence",
}

export enum VerificationAppElementType {
  "UserInformation" = "UserInformation",
  "FinancialInformation" = "FinancialInformation",
  "Residence" = "Residence",
  "ProofOfResidence" = "ProofOfResidence",
  "Selfie" = "Selfie",
  "AmlStep" = "AmlStep",
}

export type TVerificationAppElementConfigItem = {
  type: VerificationAppDocumentType;
};

export type TVerificationAppElementConfig = {
  operator: "or" | "and";
  elements: TVerificationAppElementConfigItem[];
  uiData?: boolean;
};

export type TVerificationAppElement = {
  type: VerificationAppElementType;
  /* deprecated */
  allowed_documents?: VerificationAppDocumentType[];
  config?: TVerificationAppElementConfig;
};

export type TAcceptanceScreenRules = {
  showAcceptanceScreen?: boolean;
  bannedCountries?: string[];
};

export type TOnboardingConfig = {
  elements?: TVerificationAppElement[];
  rules?: TAcceptanceScreenRules;
};

export type TVerificationApp = {
  name: string;
  companyId: string;
  settings?: {
    onboardingConfig?: string;
    onboardingConfigParsed?: TOnboardingConfig;
  };
};

export type TVerificationDocument = {
  dataAddress?: string;
  documentId: string;
  declineReason: any[];
  documentType: VerificationAppDocumentType;
};

export enum VerificationRequestStatus {
  New = "NEW",
  SentByUserToFingerPrinting = "SENT_BY_USER_TO_FINGER_PRINTING",
  AllowSendToVerification = "ALLOW_SEND_TO_VERIFICATION",
  SentToVerification = "SENT_TO_VERIFICATION",
  Canceled = "CANCELED",
  Accepted = "ACCEPTED",
  Completed = "COMPLETED",
  InformationRequested = "INFORMATION_REQUESTED",
}

export type TVerificationRequest = {
  id: string;
  status?: VerificationRequestStatus;
  category?: string;
  app_public_key?: string;
  appPublicKey?: string;
  companyId?: string;
  created_at?: string;
  documents?: {
    document: TVerificationDocument;
  }[];
  refUrlId?: string;
  uiData?: string;
  email: string;
};

export type TVerificationDocumentDataItem = {
  type: "binary" | "file";
  id: string;
  verificationDocument: string;
  dataId: string;
  dataBase64?: string;
  dataParsed?: any;
  dataLink?: string;
};

export type TVerificationDocumentFull = {
  document: TVerificationDocument;
  documents?: any;
  data: TVerificationDocumentDataItem[];
};

export type TInitialStatesModel = {
  documentType: string;
  data: {
    [Key: string]: any;
  };
};

export const AML_STORAGE = "isAmlVisited";
export const STEPPER_COMPLETED = "stepperComleted";

export const GP_URL =
  "https://play.google.com/store/apps/details?id=com.oneboard";
export const APP_STORE_URL =
  "https://apps.apple.com/ru/app/oneboard-kyc/id1660541950";
export const MOBILE_APP_URL = "oneboard://";
